function initContactMaps() {
	var mapContainers = $('.tab-map');
	if(mapContainers.length) {
			$.each(mapContainers, function(i, mapItem){
					var myMap = new ymaps.Map($(mapItem).attr('id'), {
							center: [$(mapItem).data('lat'), $(mapItem).data('lng')],
							zoom: 14,
							controls: ['zoomControl']
					});

					var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
							iconLayout: 'default#image',
							iconImageHref: '/img/pin.png',
							iconImageSize: [80, 81],
							iconImageOffset: [-30, -77]
					});
					myMap.geoObjects.add(myPlacemark);
			});
	}
}

if (typeof ymaps != 'undefined') {
	ymaps.ready(initContactMaps);
}

function ajaxSubmitForm(form, url, doneCallback, failCallback) {
	var errorsListContainer = form.find('.error-container');
	var errorsList = errorsListContainer.find('ul');

  form.find('.form__field--error').removeClass('form__field--error');
  $.ajax({
    url: url,
    method: 'post',
    data: form.serialize()
  }).fail(function(data) {
    var response = JSON.parse(data.responseText);
    errorsList.html('');
    for(var key in response) {
      form.find('[name="'+key+'"]').addClass('form__field--error');
      errorsList.append('<li>'+response[key]+'</li>');
    }
    errorsListContainer.show();
    if(failCallback) {
      failCallback();
    }
  }).done(function(data) {
  	errorsListContainer.hide();
  	errorsList.html('');
    doneCallback(data);
  });
}
setInterval(function(){
	$('input[name="intr"]').each(function(i){
		$(this).val(parseInt($(this).val())+3);
	});
}, 3000);
$('#main-slider').slick({
	prevArrow: '<button type="button" class="slick-prev main-slider__button main-slider__button_prev"><svg><use xlink:href="/img/symbol-defs.svg#svgico-back"></use></svg></button>',
	nextArrow: '<button type="button" class="slick-next main-slider__button main-slider__button_next"><svg><use xlink:href="/img/symbol-defs.svg#svgico-next"></use></svg></button>'
});
$('.product-slider').slick({
	prevArrow: '<button type="button" class="slick-prev product-slider__button product-slider__button_prev"><svg><use xlink:href="/img/symbol-defs.svg#svgico-back"></use></svg></button>',
	nextArrow: '<button type="button" class="slick-next product-slider__button product-slider__button_next"><svg><use xlink:href="/img/symbol-defs.svg#svgico-next"></use></svg></button>',
	infinite: false
});
$('#about-block__expand-text').click(function(e) {
	e.preventDefault();
	$('#about-block__hidden-text').slideToggle(300);
	$(this).toggleClass('active');
})
$('.test-section').rkTabs({
	activeCaptionClass: 'tabs-caption__item_active',
	onTabChenge: function() {
		$('.draws-slider').get(0).slick.setPosition();
	}
});
$('.contact-in-city').rkTabs({
	activeCaptionClass: 'tabs-caption__item_active',
});
$('.draws-slider').slick({
	prevArrow: $('.draws-slider__button_prev'),
	nextArrow: $('.draws-slider__button_next'),
	infinite: false
});

$("[data-fancybox]").fancybox({
	buttons : [
    'fullScreen',
    'close'
  ],
});
$('.show-request-bt').click(function(e) {
	e.preventDefault();
	$.fancybox.close();
	$.fancybox.open([
	  {
			src: $('#request-form-container'),
			type: 'html'
		}], {
			autoFocus: false
		}
	);
});

$('.header__show-callback').click(function(e) {
	e.preventDefault();
	$.fancybox.close();
	$.fancybox.open([
	  {
			src: $('#callback-form-container'),
			type: 'html'
		}], {
			autoFocus: false
		}
	);
});

$('.has-dynamic-label .form__field').focus(function(e) {
	$(this).prev('.dynamic-label').hide();
}).blur(function(e) {
	if($(this).val() == '') {
		$(this).prev('.dynamic-label').show();
	}
});

$(document).on('submit', '#request-form-container form, #callback-form-container form', function(e) {
  e.preventDefault();
  var form = $(this);
  ajaxSubmitForm(form, '/netcat/add.php?isNaked=1', function(data) {
    var response = JSON.parse(data);
    $.fancybox.close();
		$.fancybox.open('<div class="message"><div class="message__title">Ваша заявка принята</div><div class="message__text">Спасибо. И ближайшее время наши менеджеры обязательно свяжутся с Вами.</div></div>');
  });
});

// if($('#cities-list').length) {
// 	var tmpl = _.template(document.getElementById('cities-list-tpl').innerHTML);
// 	var data = cities;
// 	$('#cities-list').html(tmpl(data));
// 	$('.geography-deliveries__cities-container').mCustomScrollbar({
// 		autoDraggerLength: false
// 	});

// 	var equip = _.template(document.getElementById('delivered-equipment-list-tpl').innerHTML);
// 	$('#delivered-equipment').html('<ul>'+equip(equipment)+'</ul>');
// 	$('.geography-deliveries__delivered-equipment').mCustomScrollbar({
// 		autoDraggerLength: false
// 	});
// }

$(document).ready(function(){
	var currentNewsId = $('#full-news-text .latest-news__item').data('id');
	function getFullNews(newsId) {
			$.ajax({
					method: 'get',
					url: '/netcat/modules/default/ajax.php',
					data: {
							method: 'get_full_news',
							id: newsId
					}
			}).done(function(data) {
					$('#full-news-text').html(data);
					$('#full-news-text .product-slider').slick({
							prevArrow: '<button type="button" class="slick-prev product-slider__button product-slider__button_prev"><svg><use xlink:href="/img/symbol-defs.svg#svgico-back"></use></svg></button>',
							nextArrow: '<button type="button" class="slick-next product-slider__button product-slider__button_next"><svg><use xlink:href="/img/symbol-defs.svg#svgico-next"></use></svg></button>',
							infinite: false
					});
					$('html, body').animate({
						scrollTop: 0
					});
			}).fail(function(data) {
					console.log('fail');
			})
	}
	
	function getNewsLst(page, currentId) {
			$.ajax({
					method: 'get',
					url: '/netcat/modules/default/ajax.php',
					data: {
							method: 'get_news_list',
							page: page,
							current_id: currentId
					}
			}).done(function(data) {
					$('#news-list-container').html(data);
			}).fail(function(data) {
					console.log('fail');
			})
	}
	
	$('#news-list-container').on('click', '.latest-news__item', function(e) {
			e.preventDefault();
			if(!$(this).hasClass('latest-news__item--active')) {
					currentNewsId = $(this).data('id');
					getFullNews(currentNewsId);
					$(this).addClass('latest-news__item--active');
					$(this).siblings().removeClass('latest-news__item--active');
			}
	});
	
	$('#news-list-container').on('click', '.pager__link', function(e) {
			e.preventDefault();
			var page = $(this).data('page');
			getNewsLst(page, currentNewsId);
	});  
 	$('.header__show-cities').click(function(e){
		 e.preventDefault();
		 $.fancybox.close();
		 $.fancybox.open([
			{
				src: $('#city-selec-container'),
				type: 'html'
			}], {
				autoFocus: false
			}
		);
	 })
 	$('.toggle-menu-bt, .close-menu-bt').click(function(e) {
 		$('body').toggleClass('mob-menu-open');
 		$('.mobile-menu-box').toggle();
 		$('.main-menu__item').find('.main-menu__link--active')
						 						 .removeClass('main-menu__link--active')
						 						 .next('.main-menu_submenu')
						 						 .hide();
 	});
 	$('.mobile-menu-box .main-menu__link').click(function(e) {
 		if(!$(this).hasClass('main-menu__link--single')) {
 			e.preventDefault();
 			$(this).toggleClass('main-menu__link--active');
 			$(this).next('.main-menu_submenu').toggle();
 			$(this).parent('.main-menu__item')
 						 .siblings()
 						 .find('.main-menu__link--active')
 						 .removeClass('main-menu__link--active')
 						 .next('.main-menu_submenu')
 						 .hide();
 		}
 	});

 	$('.mobile-tab-selector').change(function(){
 		var newTab = $(this).val();
 		$(newTab).show().siblings().hide();
 	});

 	$('.sliding-tab-caption').each(function() {
 		if($(window).width() < 769) {
	 		var width = 0;
	 		$(this).find('.tabs-caption__item').each(function(){
	 			width += $(this).outerWidth(true);
	 		});
	 		$(this).find('.tabs-caption').css('width', width);
 		}
 	});
});